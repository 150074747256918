import styled from "styled-components";

import { EditorContainer } from "@LexicalEditor/styles";

export const LessonActivityContainer = styled.div`
  .section-title {
    font: 500 1rem/1.7rem "Inter";
    color: ${({ theme }) => theme?.classwork?.imersive?.buttonTextColor};

    margin: 2rem 0 0.75rem 0;
  }

  ${EditorContainer} {
    background-color: ${({ theme }) =>
      theme?.mode === "dark" ? "#1E1F23" : "#FAFAFA"};
  }

  ul {
    margin: 0;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    li {
      list-style: none;

      background-color: ${({ theme }) =>
        theme?.mode === "dark" ? "#1E1F23" : "#FAFAFA"};
      border: 1px solid
        ${({ theme }) => theme?.classwork?.imersive?.dropdownBorderColor};

      border-radius: 4px;

      padding: 0.75rem;

      font: 500 14px/21px "Inter";
      color: ${({ theme }) => theme?.classwork?.imersive?.buttonTextColor};

      display: flex;
      justify-content: space-between;
      align-items: center;

      .material-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 30ch;
      }

      button.remove-material {
        background-color: transparent;
        border: none;

        color: inherit;

        display: flex;
        align-items: center;
        justify-content: center;

        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;
