import styled from "styled-components";

export const StartQuizzButtonContainer = styled.button`
  font: 500 0.8125rem/1.0156rem "Inter";
  color: white;

  padding: 0.5rem 1rem;

  border: none;

  background-color: #0073e5;

  transition: 0.3s all;

  border-radius: 4px;

  width: fit-content;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  :not(:disabled):hover {
    cursor: pointer;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .loader {
    width: 18px;
    height: 18px;
  }
`;
