import { useContext, useState } from "react";

import Link from "next/link";

import DropDownGroups from "pages/[tenant]/g/components/ContextTopbar/components/DropDownGroups";
import { ActiveItemIndicator } from "pages/[tenant]/g/components/GroupNavigation/components/ActiveItemIndicator";
import { useGroupNavigationMethods } from "pages/[tenant]/g/hooks/useGroupNavigationMetohds";
import { useOptionsList } from "pages/[tenant]/g/hooks/useOptionsList";

import { ComponentErrorBoundary } from "shared/components/atoms/ComponentErrorBoundary";
import AuthContext from "shared/providers/context/AuthContext";
import GlobalContext from "shared/providers/context/GlobalContext";
import { useFeature } from "shared/utils/hooks/useFeature";
import useWindowSize from "shared/utils/hooks/useWindowSize";

import useGroupContext from "../../hooks/useGroupContext";
import { GroupNavigationBackground, GroupNavigationContainer } from "./styles";

export const GroupNavigation = () => {
  const {
    optionsList,
    setInfoToMoveActiveItemBottomIndicator,
    activeItemPosition,
    activeItemWidth,
    changeGroupInfoInRealTime,
  } = useOptionsList("#navigation");
  const [showMenuInMobile, setShowMenuInMobile] = useState(false);
  const { shouldRenderGroupNavigation } = useGroupNavigationMethods();

  const { allowSectionChange, group } = useGroupContext();
  const isSetupGuideEnabled = useFeature("f_setup_guide");
  const { state } = useContext(GlobalContext);
  const { loggedUser } = useContext(AuthContext);
  const isMaster = loggedUser?.id === 2;
  const completedGuide = state?.tenancy?.has_completed_guide;

  const handleClick = async ({ target }) => {
    if (!allowSectionChange) return;

    try {
      changeGroupInfoInRealTime({
        route: target.getAttribute("href"),
        slug: target.getAttribute("data-groupslug"),
      });
      setInfoToMoveActiveItemBottomIndicator(target);

      if (showMenuInMobile) setShowMenuInMobile(!showMenuInMobile);
    } catch (error) {
      console.error("Erro ao mudar de seção:", error);
    }
  };

  const { width } = useWindowSize();
  const isMobile = width <= 850;

  if (!shouldRenderGroupNavigation) return null;
  return (
    <GroupNavigationBackground
      isSetupGuide={!completedGuide && isMaster && isSetupGuideEnabled}
      isMobile={isMobile}
    >
      <GroupNavigationContainer isMobile={isMobile} show={showMenuInMobile}>
        <ul className="navigation" id="navigation">
          {optionsList?.map(
            ({ hasAccess, id, label, path, isActive, groupSlug }) =>
              hasAccess && (
                <ComponentErrorBoundary key={id}>
                  <li>
                    <Link
                      href={path}
                      className={isActive ? "active" : ""}
                      onClick={handleClick}
                      data-groupslug={groupSlug}
                      prefetch={false}
                    >
                      {label}
                    </Link>
                  </li>
                </ComponentErrorBoundary>
              ),
          )}
          <ComponentErrorBoundary>
            <ActiveItemIndicator
              activeItemPosition={activeItemPosition}
              activeItemWidth={activeItemWidth}
            />
          </ComponentErrorBoundary>
        </ul>

        {!isMobile && (
          <ComponentErrorBoundary>
            <DropDownGroups />
          </ComponentErrorBoundary>
        )}
      </GroupNavigationContainer>
    </GroupNavigationBackground>
  );
};
