import styled from "styled-components";

import { ActivityLessonVideoContainer } from "pages/[tenant]/g/[group_slug]/classwork/[topic_slug]/[activitie_slug]/components/organisms/ActivityLessonVideo/styles";
import { LessonActivityContainer } from "pages/[tenant]/g/[group_slug]/classwork/[topic_slug]/[activitie_slug]/components/templates/ImersiveLessonPage/components/molecules/LessonActivity/styles";
import { LessonQuizContainer } from "pages/[tenant]/g/[group_slug]/classwork/[topic_slug]/[activitie_slug]/components/templates/ImersiveLessonPage/components/molecules/LessonQuiz/styles";
import { LessonVideoPlayerButtonsContainer } from "pages/[tenant]/g/[group_slug]/classwork/[topic_slug]/[activitie_slug]/components/templates/ImersiveLessonPage/components/molecules/LessonVideoPlayerButtons/styles";

interface IImersiveLessonPageContainerProps {
  shouldAddExtraMarginTopToGroupNavigation: boolean;
}

export const ImersiveLessonPageContainer = styled.div<IImersiveLessonPageContainerProps>`
  margin-top: ${({ shouldAddExtraMarginTopToGroupNavigation }) =>
    shouldAddExtraMarginTopToGroupNavigation ? "7rem" : "4rem"};
  color: white;
  background-color: ${({ theme }) => theme.classwork.imersive.backgroundColor};
  display: grid;
  grid-template-areas: "content";
  align-items: start;
  padding-bottom: 7rem;

  @media (max-width: 1300px) {
    padding-bottom: 4rem;
  }
`;

export const ImersiveLessonPageContent = styled.div`
  width: 100%;
  margin: 0 auto;
  grid-area: content;
  z-index: 1;
  max-width: 1460px;
  padding: 0 1rem;

  margin-top: 3.125rem;

  display: grid;
  grid-template-columns: 1fr minmax(300px, 420px);
  gap: 3rem;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr minmax(300px, 320px);
    gap: 2.3037rem;
    margin-top: 1rem;

    max-width: 1100px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 1rem;
  }

  .loader {
    grid-column: 1/-1;
  }

  iframe {
    border-radius: 8px;
  }

  .content-grid {
    display: grid;
    grid-auto-rows: min-content;
  }

  :not(:has(.not-released-container, ${ActivityLessonVideoContainer})) {
    ${LessonVideoPlayerButtonsContainer} {
      grid-row: 4;
    }

    :has(${LessonActivityContainer}, ${LessonQuizContainer}) {
      ${LessonVideoPlayerButtonsContainer} {
        grid-row: 5;
      }
    }

    :has(${LessonActivityContainer} ~ ${LessonQuizContainer}) {
      ${LessonVideoPlayerButtonsContainer} {
        grid-row: 6;
      }
    }
  }

  :has(.lesson-html-content) {
    ${LessonVideoPlayerButtonsContainer} {
      grid-row: 3;
    }
  }
`;
