import { useRouter } from "next/router";

import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

export const useGroupNavigationMethods = () => {
  const { group } = useGroupContext();
  const { pathname } = useRouter();

  const isGroupModerator = group?.member_info?.is_moderator;
  const isInLessonPage =
    pathname?.split("/classwork")[1] === "/[topic_slug]/[activitie_slug]";

  const shouldRenderGroupNavigation = isInLessonPage ? isGroupModerator : true;

  return {
    shouldRenderGroupNavigation,
  };
};
