import styled from "styled-components";

import { StartQuizzButtonContainer } from "pages/[tenant]/g/[group_slug]/classwork/[topic_slug]/[activitie_slug]/components/templates/ImersiveLessonPage/components/atoms/StartQuizzButton/styles";

export const LessonQuizContainer = styled.div`
  margin-top: 2rem;

  .section-title {
    font: 500 1rem/1.7rem "Inter";
    color: ${({ theme }) => theme?.classwork?.imersive?.buttonTextColor};

    margin-bottom: 0.75rem;
  }

  .quizz-background {
    background-color: ${({ theme }) =>
      theme?.mode === "dark" ? "#131214" : "#fafafa"};
    border-radius: 4px;

    border: 1px solid
      ${({ theme }) => theme?.classwork?.imersive?.dropdownBorderColor};

    margin-top: 0.75rem;

    :has(${StartQuizzButtonContainer}, > .loader) {
      max-height: 300px;
      min-height: 300px;
      overflow-y: auto;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ul {
    margin: 0;

    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;

    li {
      list-style: none;
    }
  }
`;
