import styled from "styled-components";

export const ActivityLessonVideoContainer = styled.main`
  aspect-ratio: 16 / 9;
  margin: 0 auto;
  max-width: 1280px;
  margin-bottom: 0.75rem;

  iframe {
    border-radius: 4px;
  }

  @media screen and (max-width: 900px) {
    padding: 0;
  }

  @media screen and (max-width: 1366px) {
    max-width: 1030px;
  }
`;
