import { useRouter } from "next/router";

import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import { ActiveItemIndicatorContainer } from "./styles";

interface IActiveItemIndicatorProps {
  activeItemPosition: number;
  activeItemWidth: number;
}

export const ActiveItemIndicator = ({
  activeItemPosition,
  activeItemWidth,
}: IActiveItemIndicatorProps) => {
  const { group } = useGroupContext();

  const { query } = useRouter();
  const { activitie_slug, topic_slug } = query;

  const isInLessonPage = !!activitie_slug && !!topic_slug;
  const shouldRender = !isInLessonPage;

  if (!shouldRender) return null;
  return (
    <ActiveItemIndicatorContainer
      position={activeItemPosition}
      width={activeItemWidth}
      indicatorColor={group?.primary_color}
    />
  );
};
