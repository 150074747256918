import styled from "styled-components";

interface IEditorContainerProps {
  editable: boolean;
}
export const EditorContainer = styled.div<IEditorContainerProps>`
  color: ${({ theme }) => theme.lexicalEditor.fontPrimaryColor};

  overflow: hidden;
  position: relative;

  line-height: 1.25rem;
  font-weight: 400;
  text-align: left;

  width: 100%;

  :hover {
    cursor: text;
  }

  h1 {
    color: ${({ theme }) => theme.lexicalEditor.fontPrimaryColor};
  }

  .lexical-iframe-container,
  .lexical-iframe-container__focus {
    padding: 2px 0;
    border-radius: 3px;
  }

  .lexical-iframe-container {
    border: 1px solid transparent;

    iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
    }
  }

  .lexical-iframe-container__focus {
    border: 1px solid ${({ theme }) => theme.lexicalEditor.borderColor};
  }

  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }

  .lexical-iframe-container,
  iframe {
    width: 100%;
    max-width: 100%;
  }

  .editor-inner {
    position: relative;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .editor-input {
    min-height: 325px;
    padding: 0.75rem;

    caret-color: rgb(5, 5, 5);

    resize: none;
    position: relative;

    tab-size: 1;
    outline: 0;
    caret-color: ${({ theme }) => theme.lexicalEditor.fontSecondaryColor};

    border-radius: 0 0 4px 4px;
    border: ${({ editable, theme }) =>
      editable ? `1px solid ${theme.lexicalEditor.borderColor}` : "none"};

    line-height: 150%;
    font-size: 1rem;
  }

  .editor-placeholder {
    color: ${({ theme }) => theme.lexicalEditor.fontSecondaryColor};

    overflow: hidden;

    position: absolute;
    top: 1rem;
    left: 1rem;

    font-size: 1rem;
    display: inline-block;
    pointer-events: none;
    user-select: none;

    text-overflow: ellipsis;
  }

  .editor-textBold {
    font-weight: bold;
  }

  .editor-textItalic {
    font-style: italic;
  }

  .editor-textUnderline {
    text-decoration: underline;
  }

  .editor-textStrikethrough {
    text-decoration: line-through;
  }

  .editor-textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }

  .editor-textCode {
    background-color: ${({ theme }) => theme.lexicalEditor.borderColor};

    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }

  .editor-link * {
    color: rgb(33, 111, 219) !important;
    text-decoration: none;
  }

  .editor-code {
    background-color: ${({ theme }) => theme.lexicalEditor.modalBackground};
    display: block;
    padding: 0.5rem 0.5rem 0.5rem 3rem;

    line-height: 150%;
    font: 400 1rem/1.125rem "Inter";

    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    tab-size: 4;
    overflow-x: auto;
    position: relative;
  }

  .editor-code:before {
    content: attr(data-gutter);

    background-color: ${({ theme }) => theme.lexicalEditor.modalBackground};

    position: absolute;
    left: 0;
    top: 0;

    border-right: 1px solid ${({ theme }) => theme.lexicalEditor.borderColor};
    padding: 0.5rem;
    color: ${({ theme }) => theme.lexicalEditor.fontSecondaryColor};

    white-space: pre-wrap;

    text-align: right;

    min-width: 25px;
  }

  .editor-code:after {
    content: attr(data-highlight-language);

    position: absolute;
    top: 0;
    right: 3px;

    padding: 3px;

    font-size: 0.625rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.lexicalEditor.fontSecondaryColor};
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 0.5rem;
    position: relative;

    font-size: 1rem;
    font-weight: 400;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-paragraph > .editor-link > span {
    font-weight: bold;
    color: #0073e5 !important;
  }

  .editor-heading-h1 {
    font-size: 30px;
    font-weight: 700;

    margin: 0;
    margin-bottom: 8px;

    padding: 0;
  }

  .editor-heading-h2 {
    font-size: 28px;
    font-weight: 700;

    margin: 0;
    margin-bottom: 8px;

    padding: 0;
  }

  h3 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  h5 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  strong {
    font-weight: 700;
  }

  .editor-quote {
    margin: 0;
    margin-left: 1.25rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.lexicalEditor.fontSecondaryColor};
    border-left-color: ${({ theme }) => theme.lexicalEditor.fontSecondaryColor};
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 1rem;
  }

  .editor-list-ol,
  .editor-list-ul,
  li {
    padding: 0;
    margin: 0;

    color: ${({ theme }) => theme.lexicalEditor.fontPrimaryColor};
    font-size: 18px;
    text-indent: -0.6em;
    margin-left: 1rem;
    padding-left: 1rem;
  }

  pre {
    color: ${({ theme }) => theme.lexicalEditor.fontPrimaryColor};
    font-family: monospace;
    background-color: rgba(204, 204, 204, 0.1);
    padding: 0.5em 10px;
    border-radius: 8px;
    white-space: pre-wrap;
    counter-reset: listing;
    counter-increment: listing;
  }

  .editor-listitem {
    margin: 0.5rem 32px 0.5rem 32px;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 0.625rem;
  }

  pre::-webkit-scrollbar-thumb {
    background: #999;
  }

  .debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 0.625rem 0;
    margin: auto;
    display: flex;
  }

  .debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
  }

  .debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
  }

  .debug-timetravel-panel-button:hover {
    text-decoration: underline;
  }

  .debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 0.625rem;
    right: 1rem;
    position: absolute;
    background: none;
    color: #fff;
  }

  .debug-timetravel-button:hover {
    text-decoration: underline;
  }

  .emoji {
    color: transparent;
    background-size: 1rem 1rem;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
  }

  .emoji-inner {
    padding: 0 0.15em;
  }

  .emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji.happysmile {
    background-image: url(./images/emoji/1F642.png);
  }
`;
