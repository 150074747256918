import styled from "styled-components";

export const LessonVideoPlayerButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .right-buttons {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      width: 100%;
      flex-direction: column-reverse;

      button {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;
