import { useContext, useLayoutEffect } from "react";

import { usePathname } from "next/navigation";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";

import { TopFlag } from "pages/[tenant]/console/billing/components/atoms/TopFlag";
import { ConsolePageSideBar } from "pages/[tenant]/console/components/organisms/ConsolePageSideBar";
import { ConsoleLayout } from "pages/[tenant]/console/components/templates/ConsoleLayout";
import { useConsolePageConfig } from "pages/[tenant]/console/hooks/useConsolePageConfig";

import BoxSetupGuide from "shared/components/atoms/BoxSetupGuide";
import EnsinioBrand from "shared/components/atoms/EnsinioBrand";
import PageLoader from "shared/components/atoms/PageLoader";
import ConsoleScripts from "shared/components/molecules/ConsoleScripts";
import ToastNotification from "shared/components/molecules/ToastNotification";
import AuthModal from "shared/components/organisms/AuthModal";
import BackToTopFooter from "shared/components/organisms/BackToTopFooter";
import BrowserNotSupported from "shared/components/organisms/BrowserNotSupported";
import ChromeCastWarnModal from "shared/components/organisms/ChromeCastWarnModal";
import DenounceModal from "shared/components/organisms/Denounce";
import ExclusivePage from "shared/components/organisms/ExclusivePage";
import MobilePurchaseDisableModal from "shared/components/organisms/MobilePurchaseDisableModal";
import OnlyEnrolledUsersPermission from "shared/components/organisms/OnlyEnrolledUsersPermission";
import PurchaseOptionsModal from "shared/components/organisms/PurchaseOptionsModal";
import { NewTopBar } from "shared/providers/Layout/components/NewTopBar";
import { MobileFooterNavigation } from "shared/providers/Layout/components/NewTopBar/components/molecules/MobileFooterNavigation";
import { TopbarContextProvider } from "shared/providers/Layout/context/TopbarContext";
import PageErrorBoundary from "shared/providers/PageErrorBoundary";
import GlobalContext from "shared/providers/context/GlobalContext";
import Theme from "shared/styles/Theme";
import { useFeature } from "shared/utils/hooks/useFeature";
import useNextLoadingProgress from "shared/utils/hooks/useNextLoadingProgress";

import { AlertMyDocuments } from "@Console/components/molecules/AlertMyDocuments";
import { ModalSendDocuments } from "@Console/components/organisms/ModalSendDocuments";
import ModalDocuments from "@Console/components/templates/ModalDocuments";
import { UnpublishedPlatform } from "@Console/components/templates/UnpublishedPlatform";

import { AppProvider } from "../PageErrorBoundary/AppProvider/AppProvider";
import AuthContext from "../context/AuthContext";
import LayoutContext from "./context";
import { AppLayoutContainer, AppLayoutContent, Layout } from "./styles";

const AppLayout = ({ children }) => {
  const asPath = usePathname();

  const {
    shouldShowConsolePageSideBar,
    isInConsolePage,
    isInConsoleInternalPage,
    isInInternalSectionOptionPage,
    shouldShowConsolePageSideBarOptions,
  } = useConsolePageConfig();

  const { state } = useContext(GlobalContext);
  const { loggedUser, isLoadingUser } = useContext(AuthContext);
  const platformIsPrivate = state.tenancy?.settings?.site?.privatePlatform;
  const completedGuide = state?.tenancy?.has_completed_guide;
  const isMaster = loggedUser?.id === 2;
  const isSystem = loggedUser?.id !== 1;

  const statusDocuments = loggedUser?.personal_documents;

  const isInCheckoutPage = asPath?.includes("/payment");

  const personalDocuments =
    statusDocuments === null || statusDocuments?.status === "disapproved";

  const showTagPersonalDocuments =
    personalDocuments && loggedUser?.has_recipient && isSystem;

  const isInControlPanelPage = asPath?.includes("/cp");

  const isSideMenuEnabled = useFeature("f_side_menu");

  const isSetupGuideEnabled = useFeature("f_setup_guide");

  const { setBrowserNotSuportObserver, sidebarIsRendered } =
    useContext(LayoutContext);
  const pageIsLoading = useNextLoadingProgress();

  const urlValidateCertificate = asPath?.split("/")[1];

  const isVisibleEnsinioBrand =
    !asPath?.includes("/authentication") &&
    !asPath?.includes("/payment") &&
    urlValidateCertificate !== "certificate";

  const isPaymentRoute = asPath?.includes("/payment");

  const plataformIsDisabled = !state?.tenancy?.active;

  const shouldShowExclusivePage =
    !isLoadingUser &&
    !loggedUser &&
    platformIsPrivate === "1" &&
    !asPath?.includes("/first-access") &&
    !asPath?.includes("/authentication") &&
    !asPath?.includes("/reset") &&
    !asPath?.includes("/auth-token") &&
    !asPath?.includes("/payment") &&
    !asPath?.includes("/c/");

  useLayoutEffect(() => {
    if (typeof IntersectionObserver !== "function") {
      setBrowserNotSuportObserver();
    }
  }, [setBrowserNotSuportObserver]);

  return (
    <ThemeProvider theme={Theme(state.theme.value)}>
      <AppProvider>
        <AppLayoutContainer>
          <div id="modal-root" />
          <AuthModal />
          <OnlyEnrolledUsersPermission />
          <DenounceModal />
          <ToastContainer hideProgressBar={true} />
          <BrowserNotSupported />
          <MobilePurchaseDisableModal />
          <ChromeCastWarnModal />

          <Layout className="app-layout">
            <Layout
              $isInConsolePage={isInConsolePage}
              $isInCheckoutPage={isInCheckoutPage}
            >
              {!plataformIsDisabled && (
                <TopbarContextProvider>
                  <NewTopBar />
                </TopbarContextProvider>
              )}
              <OnlyEnrolledUsersPermission />

              {plataformIsDisabled && !isInConsolePage ? (
                <UnpublishedPlatform />
              ) : shouldShowExclusivePage ? (
                <ExclusivePage />
              ) : (
                <AppLayoutContent
                  $isPaymentRoute={isPaymentRoute}
                  $shouldExpandConsolePageGrid={
                    isInConsoleInternalPage &&
                    shouldShowConsolePageSideBarOptions
                  }
                  $isInConsolePage={isInConsolePage}
                  $sidebarIsRendered={isSideMenuEnabled && sidebarIsRendered}
                  $shouldShowInternalModulePageSidebar={
                    isInInternalSectionOptionPage
                  }
                  $shouldShowConsolePageSideBar={shouldShowConsolePageSideBar}
                >
                  {(isInControlPanelPage || isInConsolePage) && (
                    <ConsoleScripts />
                  )}
                  <div className="main-content-wrapper">
                    {isInConsolePage ? (
                      <PageErrorBoundary>
                        {shouldShowConsolePageSideBar && <ConsolePageSideBar />}
                        {pageIsLoading ? (
                          <PageLoader />
                        ) : (
                          <ConsoleLayout>
                            <div style={{ overflow: "auto" }}>
                              {!completedGuide &&
                                isSetupGuideEnabled &&
                                isMaster && (
                                  <BoxSetupGuide
                                    isInConsolePage={isInConsolePage}
                                  />
                                )}
                              <TopFlag />
                              {showTagPersonalDocuments && (
                                <div className="alert-my-documents-wrapper">
                                  <>
                                    <AlertMyDocuments
                                      statusDocuments={statusDocuments}
                                    />

                                    <ModalDocuments>
                                      <ModalSendDocuments />
                                    </ModalDocuments>
                                  </>
                                </div>
                              )}
                              {children}
                            </div>
                          </ConsoleLayout>
                        )}
                      </PageErrorBoundary>
                    ) : pageIsLoading ? (
                      <PageLoader />
                    ) : (
                      children
                    )}
                  </div>
                  <BackToTopFooter />

                  <ToastNotification />
                  {isVisibleEnsinioBrand && <EnsinioBrand />}
                </AppLayoutContent>
              )}

              <TopbarContextProvider>
                <MobileFooterNavigation />
              </TopbarContextProvider>
            </Layout>

            <PurchaseOptionsModal />
          </Layout>
        </AppLayoutContainer>
      </AppProvider>
    </ThemeProvider>
  );
};

export default AppLayout;
