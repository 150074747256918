import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { usePathname } from "next/navigation";

import {
  IGroupInfoInRealTime,
  IOption,
  IUseOptionsList,
} from "pages/[tenant]/g/@types";
import useGroupContext from "pages/[tenant]/g/hooks/useGroupContext";

import { useFeature } from "shared/utils/hooks/useFeature";

import { captureException, captureMessage } from "@sentry/nextjs";

export const useOptionsList = (listSelector: string): IUseOptionsList => {
  const [routeInRealTime, setRouteInRealTime] = useState("");

  const [activeItemPosition, setActiveItemPosition] = useState(null);
  const [activeItemWidth, setActiveItemWidth] = useState(null);

  const listRef = useRef<HTMLElement | null>(null);
  const activeItemRef = useRef<HTMLElement | null>(null);

  const {
    group,
    groupSlugInRealTime,
    setGroupSlugInRealTime,
    rankingIsEnabled,
  } = useGroupContext();

  const isModerator = group?.member_info?.is_moderator;
  const gamificationFeatureFlag = useFeature("f_gamification");
  const isGamificationEnabled =
    group?.enable_gamification === 1 && gamificationFeatureFlag;

  const scrollToActiveItem = () => {
    const activeItemPosition = activeItemRef.current?.offsetLeft;
    const listScrollLeft = listRef?.current?.scrollLeft;

    listRef.current.scrollLeft = activeItemPosition - listScrollLeft;
  };

  const setInfoToMoveActiveItemBottomIndicator = (activeItem: HTMLElement) => {
    const initialActiveItemPosition = activeItem?.offsetLeft;
    const initialActiveItemWidth = activeItem?.offsetWidth;

    setActiveItemPosition(initialActiveItemPosition);
    setActiveItemWidth(initialActiveItemWidth);
  };

  const handleWithActiveItemAndListRefsOnPageLoad = () => {
    try {
      const listElement = document.querySelector(listSelector) as HTMLElement;

      if (!listElement) return;

      const activeItemElement = listElement?.querySelector(
        "a.active",
      ) as HTMLElement;

      activeItemRef.current = activeItemElement;
      listRef.current = listElement;

      scrollToActiveItem();
      setInfoToMoveActiveItemBottomIndicator(activeItemElement);
    } catch (error) {
      console.log("Erro (handleWithActiveItemAndListRefsOnPageLoad): ", error);

      captureException(error);
      captureMessage("Erro (handleWithActiveItemAndListRefsOnPageLoad)");
    }
  };

  const changeGroupInfoInRealTime = ({ route, slug }: IGroupInfoInRealTime) => {
    setRouteInRealTime(route);
    setGroupSlugInRealTime(slug);
  };

  const pathname = usePathname();
  useEffect(() => setRouteInRealTime(pathname), [pathname]);

  useLayoutEffect(() => {
    handleWithActiveItemAndListRefsOnPageLoad();
  }, [isModerator, pathname, group]);

  const checkIfOptionIsActive = (optionLabel): boolean => {
    let isActive: boolean;

    switch (optionLabel) {
      case "Mural":
        isActive =
          routeInRealTime ===
            `/g/${groupSlugInRealTime || group?.slug}/community` ||
          routeInRealTime.includes("/about");

        break;

      case "Aulas":
        isActive = routeInRealTime.includes(
          `/g/${groupSlugInRealTime || group?.slug}/classwork`,
        );
        break;

      case "Gerenciar":
        isActive =
          routeInRealTime ===
          `/g/${groupSlugInRealTime || group?.slug}/moderation`;
        break;

      case "Missões":
        isActive =
          routeInRealTime ===
          `/g/${groupSlugInRealTime || group?.slug}/missions`;
        break;

      case "Ranking":
        isActive =
          routeInRealTime ===
          `/g/${groupSlugInRealTime || group?.slug}/ranking`;
        break;

      default:
        break;
    }

    return isActive;
  };

  const muralPageName = group?.group_shortcut?.mural_name || "Mural";
  const lessonPageName = group?.group_shortcut?.lesson_page_name || "Aulas";

  const isLessonPageEnabled = Boolean(
    group?.group_shortcut?.is_lesson_page_enabled,
  );

  const showLessonOption = () => {
    if (
      group?.group_shortcut?.is_lesson_page_enabled ||
      group?.first_lesson !== null
    )
      return true;
    else return false;
  };

  const navigateLessonPage = () => {
    const groupSlug = group?.slug;
    const firstTopicSlug = group?.first_lesson?.topic_slug;
    const firstLessonSlug = group?.first_lesson?.lesson_slug;
    const lastTopicSlug = group?.continue_watching?.topic_slug;
    const lastLessonSlug = group?.continue_watching?.lesson_slug;

    if (!isLessonPageEnabled) {
      if (group?.continue_watching !== null) {
        return `/g/${groupSlug}/classwork/${lastTopicSlug}/${lastLessonSlug}`;
      } else if (group?.first_lesson !== null) {
        return `/g/${groupSlug}/classwork/${firstTopicSlug}/${firstLessonSlug}`;
      }
    } else return `/g/${groupSlug}/classwork`;
  };

  const optionsList: IOption[] = [
    {
      id: "groupNavigationList1",
      label: muralPageName || "Mural",
      path: `/g/${group?.slug}/community`,
      hasAccess: Boolean(group?.group_shortcut?.is_mural_enabled),
      isActive: checkIfOptionIsActive("Mural"),
      groupSlug: group?.slug,
    },
    {
      id: "groupNavigationList2",
      label: lessonPageName,
      path: navigateLessonPage(),
      hasAccess: showLessonOption(),
      isActive: checkIfOptionIsActive("Aulas"),
      groupSlug: group?.slug,
    },
    {
      id: "groupNavigationList4",
      label: "Missões",
      path: `/g/${group?.slug}/missions`,
      hasAccess: isGamificationEnabled,
      isActive: checkIfOptionIsActive("Missões"),
      groupSlug: group?.slug,
    },
    {
      id: "groupNavigationList5",
      label: "Ranking",
      path: `/g/${group?.slug}/ranking`,
      hasAccess: rankingIsEnabled && isGamificationEnabled,
      isActive: checkIfOptionIsActive("Ranking"),
      groupSlug: group?.slug,
    },
    {
      id: "groupNavigationList3",
      label: "Gerenciar",
      path: `/g/${group?.slug}/moderation`,
      hasAccess: isModerator,
      isActive: checkIfOptionIsActive("Gerenciar"),
      groupSlug: group?.slug,
    },
  ];

  return {
    optionsList,
    activeItemPosition,
    activeItemWidth,
    routeInRealTime,
    setRouteInRealTime,
    setInfoToMoveActiveItemBottomIndicator,
    changeGroupInfoInRealTime,
  };
};
